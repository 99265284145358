











































































































































































































import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import Logo from '@/components/partials/Logo.vue';
import store from '@/store';
// eslint-disable-next-line no-unused-vars
import { GlobalFooter } from '@/main';

export interface NavItem {
  name: string;
  path: string;
  title: string;
}

@Component({
  components: { Logo },
})
export default class Navigation extends Vue {
  /**
   * Prop Values should not preseted to a default value.
   * Vue will do that for you
   */
  @Prop({ type: Array })
  public items!: NavItem[];

  public extensionOpenState: number = 220;

  public mainNavOpenState: boolean = false;

  private scrollPosition: number = 0;

  private activeEntries: string[] = [];

  public isServiceNavOpen: boolean = false;

  private extensionState: number = 0;

  public get nudgeLeft(): string {
    if (this.$vuetify.breakpoint.smAndDown) {
      return '-5px';
    }
    return '-18px';
  }

  @Watch('mainNavOpenState')
  private onNavOpenChange(value) {
    this.lockParentScroll(value);
  }

  public toggleNav() {
    this.mainNavOpenState = !this.mainNavOpenState;
  }

  public isEntryActive(typeHandle: string): boolean {
    const selectedEntry = this.activeEntries
      .find((entry) => entry === typeHandle);
    return !!selectedEntry;
  }

  public get currentWindowHeight(): number {
    return window.innerHeight;
  }

  public lockParentScroll(lock: boolean = true) {
    let position = 'static';
    let width = 'auto';
    if (lock) {
      width = '100%';
      position = 'fixed';
    }
    document.getElementsByTagName('html')[0].style.position = position;
    document.getElementsByTagName('html')[0].style.width = width;
  }

  public set toggleExtension(height: number) {
    if (this.extensionState !== 0) {
      this.extensionState = 0;
    } else {
      if (this.scrollPosition === 0) {
        this.$vuetify.goTo(1, {
          duration: 0,
          easing: 'linear',
        });
      }
      this.extensionState = height;
    }
  }

  public get extensionHeight(): number {
    return this.extensionOpenState;
  }

  public footer: GlobalFooter = {
    fromName: 'Lüscher Immo AG',
    address: 'Baslerstrasse 30',
    plz: '4601',
    ortschaft: 'Olten',
    eMail: 'info@luescher-immo.ch',
    telefonnummer: '062 205 21 30',
    openTimesTable: [],
    footerAddressBlock: '',
    footerAddressBlock2: '',
  };

  public get toggleExtension(): number {
    if (this.$vuetify.breakpoint.width < 810 && this.$vuetify.breakpoint.width > 750) {
      if (this.extensionState !== 0) {
        return this.extensionState + 20;
      }
      return 0;
    }
    return this.extensionState;
  }

  /**
   * Event triggered Method from Vuetify
   */
  public onScroll() {
    if (document.body.scrollTop || document.documentElement.scrollTop) {
      this.scrollPosition = document.body.scrollTop || document.documentElement.scrollTop;
    }
  }

  created() {
    this.$store.watch(
      (state, getters) => getters.getActiveEntries,
      (newData) => {
        this.activeEntries = [...newData.map((entry) => entry.typeHandle)];
      },
    );

    store.watch(
      (state, getters) => getters.getFooter,
      (newValue) => {
        this.footer = newValue;
      },
    );

    window.addEventListener('scroll', this.onScroll);

    window.addEventListener('resize', () => {
      if (this.extensionHeight !== 0) {
        this.extensionState = 0;
      }
    }, { passive: true });
  }
}
