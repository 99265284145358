











import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import gql from 'graphql-tag';
import MainHeader from '@/components/content/genericComponents/MainHeader.vue';
import Titel from '@/components/content/genericComponents/Titel.vue';
import TextBlock from '@/components/content/genericComponents/TextBlock.vue';
import TwoColumns from '@/components/content/genericComponents/TwoColumns.vue';
import EntryLinks from '@/components/content/genericComponents/EntryLinks.vue';
import MootIcon from '@/components/content/genericComponents/MootIcon.vue';
import CallToActionBlock from '@/components/content/genericComponents/CallToActionBlock.vue';
import SpacerBlock from '@/components/content/genericComponents/SpacerBlock.vue';

@Component({
  components: {
    Titel,
    MainHeader,
    TextBlock,
    TwoColumns,
    EntryLinks,
    MootIcon,
    CallToActionBlock,
    SpacerBlock,
  },
})
export default class GenericContent extends Vue {
  @Prop({
    type: String,
    required: true,
  })
  private section!: string;

  public genericContents: any[] = [];

  public getComponentName(fieldName: string): string {
    const matchRes = fieldName.match(/(?<ComponentName>[a-zA-Z]*)_([a-zA-Z])*/)!.groups;
    if (matchRes) {
      return matchRes.ComponentName;
    }
    return 'Default';
  }

  mounted() {
    this.loadGenericComponents(this.section);
  }

  private loadGenericComponents(section: string) {
    this.$apollo.query({
      query: gql`query {
        entries(section: "${section}") {
          ... on ${section}_Entry {
            genericContents {
              ${Titel.gQlSelect}
              ${MainHeader.gQlSelect}
              ${TextBlock.gQlSelect}
              ${TwoColumns.gQlSelect}
              ${EntryLinks.gQlSelect}
              ${MootIcon.gQlSelect}
              ${CallToActionBlock.gQlSelect}
              ${SpacerBlock.gQlSelect}
            }
          }
        }
      }
    `,
    }).then((res) => {
      if (res.data.entries != null && res.data.entries[0].genericContents != null) {
        this.genericContents = [...res.data.entries[0].genericContents];
      }
    });
  }
}
