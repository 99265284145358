

































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

interface TextBlockType {
  settingsTwoColumns: {
    width: number,
    offset: number,
    textAdjustment: string,
    hyphens: boolean,
  }[];
  imageRound: boolean;
  imageRoundSmall: boolean;
  columnLeft: string;
  columnRight: string;
}

@Component({})
export default class TwoColumns extends Vue {
  public width: number = 12;

  public offset: number = 0;

  public textLeft: string = '';

  public textRight: string = '';

  public imageRound: boolean = false;

  public imageRoundSmall: boolean = false;

  public textAdjustment: string = 'text-left';

  public hyphens: boolean = false;

  @Prop({ type: Object })
  private properties!: TextBlockType;

  public static gQlSelect: string = `
    ... on TwoColumns_Entry {
      id
      imageRound
      imageRoundSmall
      columnLeft
      columnRight
      settingsTwoColumns {
        ... on settingsTwoColumnsBlock_Entry {
          width
          offset
          textAdjustment
          hyphens
        }
      }
    }
  `;

  created() {
    this.imageRound = this.properties.imageRound;
    this.imageRoundSmall = this.properties.imageRoundSmall;
    this.textLeft = this.properties.columnLeft;
    this.textRight = this.properties.columnRight;
    const settings = this.properties.settingsTwoColumns[0];

    if (settings != null) {
      const width = +settings.width;
      let offset = +settings.offset;

      if (width + offset > 12) {
        offset = 12 - width;
      }

      this.width = width;
      this.offset = offset;
      this.textAdjustment = settings.textAdjustment;
      this.hyphens = settings.hyphens;
    }
  }
}
