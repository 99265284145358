























import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

interface TitelType {
  settingsTitel: {
    width: number,
    offset: number,
    textAdjustment: string,
    hyphens: boolean,
  }[];
  size: string;
  color: string;
  titel: string;
}

@Component({})
export default class Titel extends Vue {
  public width: number = 12;

  public offset: number = 0;

  public color: string = '';

  public title: string = '';

  public textAdjustment: string = 'text-left';

  public hyphens: boolean = false;

  public size: string = 'display-2';

  @Prop({ type: Object })
  private properties!: TitelType;

  public static gQlSelect: string = `
    ... on Titel_Entry {
      id
      titel
      size
      color
      settingsTitel {
        ... on settingsTitelBlock_Entry {
          width
          offset
          textAdjustment
          hyphens
        }
      }
    }
  `;

  created() {
    this.title = this.properties.titel;
    this.size = this.properties.size;
    this.color = this.properties.color;
    const settings = this.properties.settingsTitel[0];

    if (settings != null) {
      const width = +settings.width;
      let offset = +settings.offset;

      if (width + offset > 12) {
        offset = 12 - width;
      }

      this.width = width;
      this.offset = offset;
      this.textAdjustment = settings.textAdjustment;
      this.hyphens = settings.hyphens;
    }
  }
}
