






















import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

interface MainHeaderType {
  settingsHeader: {
    width: number,
    offset: number,
    textAdjustment: string,
    hyphens: boolean,
  }[];
  titel: string;
}

@Component({})
export default class Titel extends Vue {
  public width: number = 12;

  public offset: number = 0;

  public color: string = '';

  public title: string = '';

  public textAdjustment: string = 'text-left';

  public hyphens: boolean = false;

  @Prop({ type: Object })
  private properties!: MainHeaderType;

  public static gQlSelect: string = `
    ... on mainHeader_Entry {
      id
      titel
      settingsHeader {
        ... on settingsTitel2Block_Entry {
          width
          offset
          textAdjustment
          hyphens
        }
      }
    }
  `;

  created() {
    this.title = this.properties.titel;
    const settings = this.properties.settingsHeader[0];

    if (settings != null) {
      const width = +settings.width;
      let offset = +settings.offset;

      if (width + offset > 12) {
        offset = 12 - width;
      }

      this.width = width;
      this.offset = offset;
      this.textAdjustment = settings.textAdjustment;
      this.hyphens = settings.hyphens;
    }
  }
}
