import './styles/base.scss';
import Vue from 'vue';
import Vuelidate from 'vuelidate';
import 'es6-promise/auto';
import gql from 'graphql-tag';
import VShowSlide from 'v-show-slide';
import VueAnalytics from 'vue-analytics';
import App from './App.vue';
import router from './router';
import store from './store';
import './registerServiceWorker';
import vuetify from './plugins/vuetify';
import { createProvider } from './vue-apollo';
// eslint-disable-next-line import/no-extraneous-dependencies
const moment = require('moment');
// eslint-disable-next-line import/no-extraneous-dependencies
require('moment/locale/de');

Vue.use(require('vue-moment'), { moment });

Vue.use(VShowSlide);

Vue.use(Vuelidate);

Vue.use(VueAnalytics, {
  id: 'UA-181454883-1',
  router,
});

Vue.config.productionTip = false;

const vueInstance = new Vue({
  router,
  store,
  vuetify,
  // @ts-ignore
  apolloProvider: createProvider(),
  render: (h) => h(App),
}).$mount('#app');

vueInstance.$apollo.query({
  query: gql`query {
     entries {
      typeHandle
    }
  }`,
}).then((res) => {
  if (res.data.entries != null) {
    store.state.activeEntries = res.data.entries;
  }
});

/**
 * Wird gebraucht um mit dem Iframe Formular zu komunizieren.
 * @param event
 */
function receiveMessage(event) {
  store.state.postMessages = event.data;
}
window.addEventListener('message', receiveMessage, false);

vueInstance.$apollo.query({
  query: gql`query {
    categories(group: "configuration") {
      ... on configuration_Category {
        id
        title
        iconName
        simpleDescription
      }
    }
  }`,
}).then((res) => {
  if (res.data.categories != null) {
    store.state.configurations = res.data.categories;
  }
});

export interface GlobalSettings {
  shareText: string;
  headerArrows: boolean;
  headerBilderKurzwahl: boolean;
}

export interface GlobalFooter {
  fromName: string;
  address: string;
  telefonnummer: string;
  eMail: string;
  plz: string;
  ortschaft: string;
  footerAddressBlock: string;
  footerAddressBlock2: string;
  openTimesTable: {column1: string, column2: string, column3: string}[]
}

export type GlobalSet = GlobalSettings | GlobalFooter;

vueInstance.$apollo.query<{globalSets: GlobalSet[]}>({
  query: gql`
    query {
      globalSets(handle: ["footer","settings"]) {
        ... on settings_GlobalSet {
          shareText
          headerArrows
          headerBilderKurzwahl
        }
        ... on footer_GlobalSet {
          fromName,
          address,
          telefonnummer,
          eMail,
          plz,
          ortschaft,
          footerAddressBlock,
          footerAddressBlock2,
          openTimesTable {
            column1
            column2
            column3
          }
        }
      }
    }
  `,
}).then((res) => {
  if (res.data.globalSets != null) {
    const [settings, footer] = res.data.globalSets;
    store.state.settings = settings;
    store.state.footer = footer;
  }
});
